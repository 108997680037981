@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "node_modules/bootstrap-icons/font/bootstrap-icons";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '../node_modules/font-awesome/css/font-awesome.css';

.theme-heading {
    font-family: 'Anton', sans-serif;
    font-display: swap;
}

.theme-li, 
.theme-a, 
.theme-p {
    font-family: 'Lato', sans-serif;
    color: #431115;
    font-display: swap;
}

.theme-button {
    background-color: #431115;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

@media only screen and (min-width: 768px) {
    .hidden-from-left {
        opacity: 0;
        filter: blur(5px);
        transform: translate(-100%);
        transition: all 1s;
    }

    .hidden-from-right {
        opacity: 0;
        filter: blur(5px);
        transform: translate(100%);
        transition: all 1s;
    }

    .hidden {
        opacity: 0;
        transition: all 2s;
    }
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media(prefers-reduced-motion) {
    .hidden-from-left {
        transition: none;
    }

    .hidden-from-right {
        transition: none;
    }
}

.heading {
    font-size: 40px;
}

html,
body {
    height: 100%;
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

.active-tab {
    background-color: #007bff;
    color: #fff;
    border-radius: 25px;
}

.tab-active {
    border-bottom: 1px solid orange;
  }